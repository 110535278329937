import { post, get, postUploadData, put, postIntegration, getDownloadDatas, gets } from "../../api/api";
import {
  ADD_DYNAMIC_DATA,
  GET_BRANCH_DETAILS,
  GET_MASTER_DATA,
  INSERT_BLOB_FILE,
  UPDATE_DYNAMIC_DATA,
  GET_SUBORDINATES_LIST,
  CHECKIN_URL,
  FINISH_DAY_ACTIVITY,
  GET_MASTER_DATA_BY_CHANNEL_ID,
  LMS_HRMS_CHECKIN_URL,
  FUNCTIONS_KEY_CHECKIN,
  DISTRICT_LIST,
  IS_FINISH_DAY_DONE,
  GET_AGENCY_USER_MAPPING,
  GET_ACTIVITY_TYPE_DETAILS_COUNTS_BY_USER_ID,
  CAMPAIGN_LEAD_LISTING,
  PERSISTANCY_LEAD_LISTING,
  BUSSINESS_LEAD_LISTING,

  INSERT_CAMPAIGN_BLOB_FILE,
  INSERT_PERSISTENCY_BLOB_FILE,
  INSERT_CAMPAIGN_ACTIVITY_BLOB_FILE,

  INSERT_AGENCY_CAMPAIGN_BLOB_FILE,
  INSERT_AGENCY_PERSISTENCY_BLOB_FILE,
  INSERT_AGENCY_CAMPAIGN_ACTIVITY_BLOB_FILE,

  INSERT_CSC_CAMPAIGN_BLOB_FILE,
  INSERT_CSC_PERSISTENCY_BLOB_FILE,
  INSERT_CSC_CAMPAIGN_ACTIVITY_BLOB_FILE,

  LIST_CAMPAIGN_BLOB_FILES,
  CAMPAIGN_BLOB_FILE_DOWNLOAD,
  CONVERT_TO_EXCEL,
  DOWNLOAD_BUSINESS_LIST,
  DOWNLOAD_PERSISTENCY_LIST,
  DOWNLOAD_CAMPAIGN_LIST,
  DOWNLOAD_CAMPAIGNACTIVITY_LIST,
  GET_NH_LIST,
  GET_SMA_REGION_MAP_BY_USERID,
  GET_CAMPAIGN_NAME,
  GET_CAMPAIGN_ACTIVITY_BY_ID,
  GET_CAMPAIGN_ACTIVITY_BY_ID_CSC,
  SUBORDINATE_BY_USER_ID_AND_LEVEL,
  CSC_CAMPAIGN_ACTIVITY_LISTING,
  DOWNLOAD_CSC_CAMPAIGNACTIVITY_LIST,
  DOWNLOAD_ABHSMAACTIVITY_LIST,
  GET_FILTER_LIST_BY_LEVEL,
  DOWNLOAD_BRANCH_PARTNER_AND_CATEGORYWISE,
  DOWNLOAD_MUST_DO_ACTIVITY_SUMMARY, 
  DOWNLOAD_BUSINESS_DEVELOPMENT_ACTIVITY,
  DOWNLOAD_BFD_WISE_ACTIVITY,
  DOWNLOAD_PARTNER_ENGAGEMENT_ACTIVITY,
  DOWNLOAD_LEAD_GENERATION_ACTIVITY,
  DOWNLOAD_BROCA_ACTIVITY_LIST,
  DOWNLOAD_BROCA_LEAD_LIST,
  DOWNLOAD_GCL_BUSINESS_LIST,
  DOWNLOAD_GCL_ACTIVITY_LIST,
  VALIDATE_PERSISTENCY_LEAD_FOR_UPDATE,
  GET_OTP_FOR_RESET_DEVICE,
  VALIDATE_OTP_FOR_RESET_DEVICE,
  VALIDATE_USER_RESET_DEVICE,
  VALIDATE_USER_CONSENT_DEVICE
} from "../../api/baseURL";
import { getDropDownOptions } from "../../pages/postlogin/activity/gcl/createActivityForm";
import { CONFIGS } from "../../utils/config";
import { add } from "../../offlineManager/localbase";
import moment from "moment";
import { getCampaignFilterNames } from "../../redux-reducer/reducer";
import { getActivitiesForHNI, getSRMList } from "./createActivityActions";
import { getDropdownDataAdmin } from "../../utils/utils";
//import { persistancyLeadListLoading } from "../../redux-reducer/reducer/persistancyLeadReducer";
import { GET_ACTIVITY_BY_CSV_FILE, GET_LEAD_BY_CSV_FILE, GET_PARTNER_DETAILS,SET_COLLATERAL_FLAG } from "../../api/baseURL";

const MASTER_TABLES_TO_LOADS = [
  CONFIGS.MASTER_DATA_TABLES.SUB_ACTIVITY,
  CONFIGS.MASTER_DATA_TABLES.FPMFPH_MEETING_TYPE,
  CONFIGS.MASTER_DATA_TABLES.EXPENSE_TYPE,
  CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT,
  CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE,
  CONFIGS.MASTER_DATA_TABLES.PRODUCTS,
  CONFIGS.MASTER_DATA_TABLES.LEADS,
  CONFIGS.MASTER_DATA_TABLES.SPTYPES,
  CONFIGS.MASTER_DATA_TABLES.SALUTATION,
  // CONFIGS.MASTER_DATA_TABLES.LEADSOURCE,
  // CONFIGS.MASTER_DATA_TABLES.RESCHEDULE_REASON,
  // CONFIGS.MASTER_DATA_TABLES.DROPPED_REASON,
  // CONFIGS.MASTER_DATA_TABLES.LEAD_CALLBACK_REASON,
  CONFIGS.MASTER_DATA_TABLES.FOLLOWUP_REASON,
  CONFIGS.MASTER_DATA_TABLES.PERSISTANCY_DROPPED_REASON,
  CONFIGS.MASTER_DATA_TABLES.PERSISTANCY_RESCHEDULE_REASON,
  CONFIGS.MASTER_DATA_TABLES.PERSISTANCY_CALLBACK_REASON,
  CONFIGS.MASTER_DATA_TABLES.PRIORITY,
  CONFIGS.MASTER_DATA_TABLES.GCLACTIVITYTYPE,
  CONFIGS.MASTER_DATA_TABLES.PAYMENT_TYPE,
  CONFIGS.MASTER_DATA_TABLES.PAYMENT_SUB_TYPE,
  CONFIGS.MASTER_DATA_TABLES.LOANTYPE,
  CONFIGS.MASTER_DATA_TABLES.GCL_CALL_DURATION,
  CONFIGS.MASTER_DATA_TABLES.CHECK_LIST_TYPE,
  CONFIGS.MASTER_DATA_TABLES.CHECK_LIST_STATUS,
  // CONFIGS.MASTER_DATA_TABLES.BROCA_ACTIVITY_PLANNED,
  // CONFIGS.MASTER_DATA_TABLES.BROCA_TRAINING_TOPIC,
  // CONFIGS.MASTER_DATA_TABLES.BROCA_JOINT_CALL_ACTIVITY,
  // CONFIGS.MASTER_DATA_TABLES.BROCA_JOINT_CALL_ACTIVITY_TYPE,
  // CONFIGS.MASTER_DATA_TABLES.BROCA_CAMPAIGNING_DONE,
  // CONFIGS.MASTER_DATA_TABLES.BROCA_DESIGNATION,
  // CONFIGS.MASTER_DATA_TABLES.BROCA_LAST_ASSIGNMENTOF,
  // CONFIGS.MASTER_DATA_TABLES.BROCA_MEETING_PLANNED_WITH,
  // CONFIGS.MASTER_DATA_TABLES.MSME_LOAN_TYPE,
  CONFIGS.MASTER_DATA_TABLES.SMA_ACTIVITY_DURATION,
  CONFIGS.MASTER_DATA_TABLES.Configuration_Setting,
  CONFIGS.MASTER_DATA_TABLES.GoalType,
];

const MASTER_TABLES_TO_LOAD_FOR_CHANNELID = [
  CONFIGS.MASTER_DATA_TABLES.ACTIVITY,
  CONFIGS.MASTER_DATA_TABLES.ACTIVITY_STATUS,
];

const MASTER_TABLES_TO_LOAD_BANCA_CHANNELID = [
  CONFIGS.MASTER_DATA_TABLES.LEADSOURCE,
  CONFIGS.MASTER_DATA_TABLES.LEAD_CALLBACK_REASON,
  CONFIGS.MASTER_DATA_TABLES.DROPPED_REASON,
  CONFIGS.MASTER_DATA_TABLES.RESCHEDULE_REASON,
];

const MASTER_TABLES_TO_LOAD_GCL_CHANNELID = [
];

const MASTER_TABLES_TO_LOAD_CSC_CHANNELID = [
  CONFIGS.MASTER_DATA_TABLES.LEAD_CALLBACK_REASON,
  CONFIGS.MASTER_DATA_TABLES.LEADSOURCE,
  CONFIGS.MASTER_DATA_TABLES.DROPPED_REASON,
  CONFIGS.MASTER_DATA_TABLES.RESCHEDULE_REASON,
];

const MASTER_TABLES_TO_LOAD_CSC = [
  CONFIGS.MASTER_DATA_TABLES.TRAINING_FOR,
  CONFIGS.MASTER_DATA_TABLES.MONTHLY_REVIEW_FOR,
  CONFIGS.MASTER_DATA_TABLES.MEETING_VISIT_FOR,
  CONFIGS.MASTER_DATA_TABLES.STATE_ID,
  CONFIGS.MASTER_DATA_TABLES.MEETING_TYPE,
  CONFIGS.MASTER_DATA_TABLES.DISRICT_ID,
];

const MASTER_TABLES_TO_LOAD_AGENCY = [
  CONFIGS.MASTER_DATA_TABLES.ACTIVITY_DONE_WITH,
  CONFIGS.MASTER_DATA_TABLES.RECRUITMENT_FOR,
  CONFIGS.MASTER_DATA_TABLES.VISIT_TO
];

const MASTER_TABLES_TO_LOAD_AGENCY_CHANNELID = [
  CONFIGS.MASTER_DATA_TABLES.LEAD_CALLBACK_REASON,
  CONFIGS.MASTER_DATA_TABLES.LEADSOURCE,
  CONFIGS.MASTER_DATA_TABLES.DROPPED_REASON,
  CONFIGS.MASTER_DATA_TABLES.RESCHEDULE_REASON,
];


const MASTER_TABLE_TO_LOAD_BROCA = [
  CONFIGS.MASTER_DATA_TABLES.BROCA_TOPICS_COVERED,
  CONFIGS.MASTER_DATA_TABLES.BROCA_TRAINNIG_ATTENDEE,
  CONFIGS.MASTER_DATA_TABLES.BROCA_DESIGNATION,
  CONFIGS.MASTER_DATA_TABLES.BROCA_BFDACTIVITY,
  CONFIGS.MASTER_DATA_TABLES.LEAD_CALLBACK_REASON, 
  CONFIGS.MASTER_DATA_TABLES.RESCHEDULE_REASON,
  CONFIGS.MASTER_DATA_TABLES.DROPPED_REASON,

 
  CONFIGS.MASTER_DATA_TABLES.BROCA_ACTIVITY_PLANNED,
  CONFIGS.MASTER_DATA_TABLES.BROCA_TRAINING_TOPIC,
  CONFIGS.MASTER_DATA_TABLES.BROCA_JOINT_CALL_ACTIVITY,
  CONFIGS.MASTER_DATA_TABLES.BROCA_JOINT_CALL_ACTIVITY_TYPE,
  CONFIGS.MASTER_DATA_TABLES.BROCA_CAMPAIGNING_DONE,
  CONFIGS.MASTER_DATA_TABLES.BROCA_DESIGNATION,
  CONFIGS.MASTER_DATA_TABLES.BROCA_LAST_ASSIGNMENTOF,
  CONFIGS.MASTER_DATA_TABLES.BROCA_MEETING_PLANNED_WITH,
]

const MASTER_TABLE_TO_LOAD_MSME =[
  CONFIGS.MASTER_DATA_TABLES.MSME_LOAN_TYPE,
]
export const MASTERDATA_CHANNELID = [
  { id: 1, masterData: MASTER_TABLES_TO_LOAD_BANCA_CHANNELID },
  { id: 2, masterData: MASTER_TABLES_TO_LOAD_CSC_CHANNELID },
  { id: 13, masterData: MASTER_TABLES_TO_LOAD_GCL_CHANNELID },
];
export const MASTERDATA = [
  { id: 1, masterData: MASTER_TABLES_TO_LOADS },
  { id: 2, masterData: MASTER_TABLES_TO_LOAD_CSC },
  { id: 13, masterData: MASTER_TABLES_TO_LOADS },
];
export const loadMasterData = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    // payload.userState.profileInfo.channelid :: this is the key

    //loop for masterdata for channelID//

    //loop for masterdata for channelID//

    // to write logic for conditional rendering MASTERDATA_CHANNELID
    MASTER_TABLES_TO_LOAD_FOR_CHANNELID.forEach((config) => {
      dispatch(
        getMasterDataByChannelId({
          tableName: config.TABLE_NAME,
          config: config,
          channelId: payload.userState.profileInfo.channelid
            ? payload.userState.profileInfo.channelid
            : 1,
        })
      ); // 3 }))
    });
    
    if (payload.userState.profileInfo.channelid === 1) {
      MASTER_TABLES_TO_LOAD_BANCA_CHANNELID.forEach((config) => {
        dispatch(
          getMasterDataByChannelId({
            tableName: config.TABLE_NAME,
            config: config,
            channelId: payload.userState.profileInfo.channelid
              ? payload.userState.profileInfo.channelid
              : 1,
          })
        );
      });
    }
    
    if (payload.userState.profileInfo.channelid === 13) {
      MASTER_TABLES_TO_LOAD_GCL_CHANNELID.forEach((config) => {
        dispatch(
          getMasterDataByChannelId({
            tableName: config.TABLE_NAME,
            config: config,
            channelId: payload.userState.profileInfo.channelid
              ? payload.userState.profileInfo.channelid
              : 13,
          })
        );
      });
    }
    ////---loop ends--///
    ///loop which will be just masterdata no need of channelid MASTERDATA//
    if (payload.userState.profileInfo.channelid === 2) {
      MASTER_TABLES_TO_LOAD_CSC_CHANNELID.forEach((config) => {
        dispatch(
          getMasterDataByChannelId({
            tableName: config.TABLE_NAME,
            config: config,
            channelId: payload.userState.profileInfo.channelid
              ? payload.userState.profileInfo.channelid
              : 2,
          })
        );
      });
    }
    MASTER_TABLES_TO_LOAD_CSC.forEach((config) => {
      dispatch(
        getMasterData({
          tableName: config.TABLE_NAME,
          config: config,
          channelId: payload.userState.profileInfo.channelid
            ? payload.userState.profileInfo.channelid
            : 1,
        })
      );
    });

    dispatch(getActivitiesForHNI());
    dispatch(getSRMList(payload.userState.profileInfo.userId));
    if (payload.userState.profileInfo.channelid === 3) {
      MASTER_TABLES_TO_LOAD_AGENCY_CHANNELID.forEach((config) => {
        dispatch(
          getMasterDataByChannelId({
            tableName: config.TABLE_NAME,
            config: config,
            channelId: payload.userState.profileInfo.channelid
              ? payload.userState.profileInfo.channelid
              : 3,
          })
        );
      });
    }

    MASTER_TABLES_TO_LOAD_AGENCY.forEach((config) => {
      dispatch(
        getMasterData({
          tableName: config.TABLE_NAME,
          config: config,
          channelId: payload.userState.profileInfo.channelid
            ? payload.userState.profileInfo.channelid
            : 1,
        })
      );
    });

    if (payload.userState.profileInfo.channelid === 21) {
      MASTER_TABLE_TO_LOAD_BROCA.forEach((config) => {
        dispatch(
          getMasterData({
            tableName: config.TABLE_NAME,
            config: config,
            channelId: payload.userState.profileInfo.channelid
              ? payload.userState.profileInfo.channelid
              : 1,
          })
        );
      });
    }
    if (payload.userState.profileInfo.channelid === 79) {
      MASTER_TABLE_TO_LOAD_MSME.forEach((config) => {
        dispatch(
          getMasterData({
            tableName: config.TABLE_NAME,
            config: config,
            channelId: payload.userState.profileInfo.channelid
              ? payload.userState.profileInfo.channelid
              : 1,
          })
        );
      });
    }
    //loop ends here//

    MASTER_TABLES_TO_LOADS.forEach((config) => {
      dispatch(
        getMasterData({
          tableName: config.TABLE_NAME,
          config: config,
          channelId: payload.userState.profileInfo.channelid
            ? payload.userState.profileInfo.channelid
            : 1,
        })
      );
    });

    if (payload.userState.profileInfo.userId !== 0) {
      dispatch(
        getUserBranches({ userId: payload.userState.profileInfo.userId })
      );
      dispatch(getCampaignFilterNames(payload.userState.profileInfo.userId));
      // dispatch(getAgencyRecruitReasonData(payload.userState.profileInfo.channelid));
    }
    ////---loop ends--///

    ///loop which will be just masterdata no need of channelid MASTERDATA//

    resolve({ load: true });
  });
};

export const addDynamicData = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    const formdata = {
      jsondata: JSON.stringify(payload.data),
      CreatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      ModifiedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      // ModifiedBy: "",
    };
    try {
      const res = await post(ADD_DYNAMIC_DATA + payload.formName, formdata);
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export const updateDynamicData = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    const formdata = {
      jsondata: JSON.stringify(payload.data),
      CreatedDate: new Date().toISOString().replace("T", " ").replace("Z", ""),
      ModifiedDate: new Date().toISOString().replace("T", " ").replace("Z", ""),
    };

    try {
      const res = await put(UPDATE_DYNAMIC_DATA + payload.formName, formdata);
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export const finishdayActivity = (userID) => async (dispatch) => {
  try {
    await post(FINISH_DAY_ACTIVITY + userID).then((response) => {
      dispatch(activateSuccessMessageBar("Succesfully"));
    });
  } catch (e) {
    const { Responsemessage } = e?.response?.data;
    dispatch(activateErrorMessageBar(Responsemessage));
  }
};

export const isfinishdayActivityDone = (userID) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await get(IS_FINISH_DAY_DONE + userID).then((response) => {
        resolve(response);
      });
    } catch (e) {
      reject(e);
      dispatch(activateErrorMessageBar(e?.data?.Responsemessage));
    }
  });
};


export const finishDayActivityCreate = (userID) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await post(FINISH_DAY_ACTIVITY + userID).then((response) => {
        resolve(response);
      });
    } catch (e) {
      reject(e);
      console.log("error", e);
      dispatch(activateErrorMessageBar(e?.data?.Responsemessage));
    }
  });
};

export const uploadFileToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};

export const uploadPersistencyFileToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_CAMPAIGN_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};

export const uploadAgencyCampaignFileToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_AGENCY_CAMPAIGN_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};

export const uploadCscCampaignFileToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_CSC_CAMPAIGN_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};
export const uploadAgencyPersistencyFilesToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_AGENCY_PERSISTENCY_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};
export const uploadCscPersistencyFilesToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_CSC_PERSISTENCY_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};

export const uploadPersistencyFilesToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_PERSISTENCY_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};

export const uploadCampaignActivitiesFilesToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_CAMPAIGN_ACTIVITY_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};

export const uploadAgencyCampaignActivitiesFilesToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_AGENCY_CAMPAIGN_ACTIVITY_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};
export const uploadCscCampaignActivitiesFilesToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_CSC_CAMPAIGN_ACTIVITY_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};

export const uploadGClCampaignActivitiesFilesToBlob = (payload) => (dispatch, getState) => {
  dispatch({ type: "ACTIVATE_LOADER", payload: true });
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("asset", payload.file, payload.fileName);
      const res = await postUploadData(INSERT_CSC_CAMPAIGN_ACTIVITY_BLOB_FILE, formdata);
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      resolve(res);
    } catch (err) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(err);
    }
  });
};

export const campainLeadUploadedFileLists = (filetype) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await get(LIST_CAMPAIGN_BLOB_FILES + filetype).then((response) => {
        resolve(response);
      });
    } catch (e) {
      reject(e);
      console.log("error", e);
      dispatch(activateErrorMessageBar(e?.data?.Responsemessage));
    }
  });
};

export const campainLeadDownloadFile = (payload) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await gets(CAMPAIGN_BLOB_FILE_DOWNLOAD + payload.filetype + "/" + payload.fileName).then((response) => {
        dispatch({ type: "DEACTIVATE_LOADER", payload: false });
        resolve(response);
      });
    } catch (e) {
      dispatch({ type: "DEACTIVATE_LOADER", payload: false });
      reject(e);
      console.log("error", e);
      dispatch(activateErrorMessageBar(e?.data?.Responsemessage));
    }
  });
};

export const getMasterData = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(GET_MASTER_DATA + payload.tableName, {});
      if (res.status === 200) {
        const dropDownOptions = getDropDownOptions(
          res.data,
          payload.config,
          payload.channelId
        );
        dispatch({
          type: payload.tableName,
          payload: dropDownOptions,
        });
        const dataObject = {
          [payload.tableName]: dropDownOptions,
        };
        add(payload.tableName, dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => console.log(err));
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getMasterDataForAdminByChannelId = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${GET_MASTER_DATA_BY_CHANNEL_ID}${payload.tableName}?Channelid=${payload.channelId}`,
        {}
      );
      if (res.status === 200) {
        // payload.tableName === 'Branches' ?  res.data.splice(0, 10) :
        const dropDownOptions = getDropdownDataAdmin(
          res.data,
          payload.config,
          payload.channelId
        );
        dispatch({
          type: payload.tableName,
          payload: dropDownOptions,
        });
        const dataObject = {
          [payload.tableName]: dropDownOptions,
        };
        add(payload.tableName, dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => console.log(err));
      }
    } catch (err) {
      reject(err);
    }
  });
};
export const getMasterDataForAdmin = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${GET_MASTER_DATA}${payload.tableName}`,
        {}
      );
      if (res.status === 200) {
        // payload.tableName === 'Branches' ?  res.data.splice(0, 10) :
        const dropDownOptions = getDropdownDataAdmin(
          res.data,
          payload.config,
        );
        dispatch({
          type: payload.tableName,
          payload: dropDownOptions,
        });
        const dataObject = {
          [payload.tableName]: dropDownOptions,
        };
        add(payload.tableName, dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => console.log(err));
      }
    } catch (err) {
      reject(err);
    }
  });
};


export const getMasterDataByChannelId = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(
        `${GET_MASTER_DATA_BY_CHANNEL_ID}${payload.tableName}?Channelid=${payload.channelId}`,
        {}
      );
      if (res.status === 200) {
        // payload.tableName === 'Branches' ?  res.data.splice(0, 10) :
        const dropDownOptions = getDropDownOptions(
          res.data,
          payload.config,
          payload.channelId
        );
        dispatch({
          type: payload.tableName,
          payload: dropDownOptions,
        });
        const dataObject = {
          [payload.tableName]: dropDownOptions,
        };
        add(payload.tableName, dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => console.log(err));
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getUserBranches = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(activateLoader());
      const res = await get(`${GET_BRANCH_DETAILS}/${payload.userId}`, {});
      dispatch(deActivateLoader());
      if (res.status === 200) {
        const dropDownOptions = getDropDownOptions(
          res.data,
          CONFIGS.MASTER_DATA_TABLES.BRANCHES,
          ""
        );
        dispatch({
          type: CONFIGS.MASTER_DATA_TABLES.BRANCHES.TABLE_NAME,
          payload: dropDownOptions,
        });
        dispatch({
          type: "userBranchData",
          payload: res.data,
        });
      }
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export const setZMAList = (data) => ({
  type: "ZMAList",
  payload: data
})

export const setAdvisorList = (data) => ({
  type: "AdvisorList",
  payload: data
})

export const setPremierZMAList = (data) => ({
  type: "PremierZMAList",
  payload: data
})

export const setEAPList = (data) => ({
  type: "EAPList",
  payload: data
})

export const setActivityMasterData = (data) => ({
  type: "ACTIVITY_MASTER_DATA",
  payload: data,
});

export const activateSuccessMessageBar = (message) => ({
  type: "ACTIVATE_SUCCESS_MESSAGE_BAR",
  payload: message,
});

export const activateErrorMessageBar = (message) => ({
  type: "ACTIVATE_ERROR_MESSAGE_BAR",
  payload: message,
});

export const deactivateMessageBar = () => ({
  type: "DEACTIVATE_MESSAGE_BAR",
  payload: false,
});

export const activateLoader = () => ({
  type: "ACTIVATE_LOADER",
  payload: true,
});

export const closeNotification = () => ({
  type: "CLOSE_NOTIFICATION",
  payload: true,
});
export const readAllNotification = (O) => ({
  type: "READ_ALL_NOTIFICATION",
  payload: O,
});

export const deActivateLoader = () => ({
  type: "DEACTIVATE_LOADER",
  payload: false,
});

export const setBreadcrumbs = (breadcrums = []) => ({
  type: "SET_BREADCRUMBS",
  payload: breadcrums,
});

export const smaTrainingType = (messege) => ({
  type: "smaTrainingType",
  payload: messege,
});

export const SmaReasons = (data) => ({
  type: "SmaReasons",
  payload: data,
});

export const SMAActivityType = (messege) => ({
  type: "SMAActivityType",
  payload: messege,
});
export const get_SMA_BDM_Branch = (messege) => ({
  type: "get_SMA_BDM_Branch",
  payload: messege,
});
export const smaBankRegion = (messege) => ({
  type: "smaBankRegion",
  payload: messege,
});

export const activityTypeDetailCount = (messege) => ({
  type: "activityTypeDetailCount",
  payload: messege,
});
export const getBranchDetails = (messege) => ({
  type: "getBranchDetails",
  payload: messege,
});
export const getPatnerDetails = (messege) => ({
  type: "getPatnerDetails",
  payload: messege,
});
export const setCalculatorUsed = (messege) => ({
  type: "isCalculatorUsed",
  payload: messege,
});
export const setIntrestedLeadDob = (messege) => ({
  type: "IntrestedLeadDob",
  payload: messege,
});

export const setpremiumCalculated = (messege) => ({
  type: "premiumCalculated",
  payload: messege,
});
export const setIsUserFound= (messege) => ({
  type: "isUserFound",
  payload: messege,
});
export const setIsisConsent= (messege) => ({
  type: "isConsent",
  payload: messege,
});
export const setHeaderData= (messege) => ({
  type: "headerData",
  payload: messege,
});

export const getSubordinateList = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_SUBORDINATES_LIST}${payload.userId}`, {});
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getsubordinatedetailsbylevel = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${SUBORDINATE_BY_USER_ID_AND_LEVEL}${payload.userId}${'/'}${payload.level}`);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const NHList = (payload) => (dispatch, getState) => {

  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_NH_LIST}?Channelid=${payload.channelId}`);
      console.log("response from common.js is", res);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
export const getFilterByLevel = (payload) => (dispatch, getState) => {

  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_FILTER_LIST_BY_LEVEL}?level=${payload.userLevel}&ChannelID=${payload.channelId}`);
      console.log("response from common.js is", res);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getCampaignActivityDetails = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(GET_CAMPAIGN_ACTIVITY_BY_ID + payload.activityId, {})
      resolve(res)
    } catch (err) {
      console.log(err)
      dispatch({
        type: 'DEACTIVATE_LOADER',
        payload: false
      })
      dispatch({
        type: 'ACTIVATE_ERROR_MESSAGE_BAR',
        payload: err.data
      })
      reject(err)
    }
  })
}
export const getCampaignActivityById = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(GET_CAMPAIGN_ACTIVITY_BY_ID_CSC + payload.activityId, {})
      resolve(res)
    } catch (err) {
      console.log(err)
      dispatch({
        type: 'DEACTIVATE_LOADER',
        payload: false
      })
      dispatch({
        type: 'ACTIVATE_ERROR_MESSAGE_BAR',
        payload: err.data
      })
      reject(err)
    }
  })
}

export const getCampaignName = (payload) => (dispatch, getState) => {

  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_CAMPAIGN_NAME}${payload.ChannelId}/${payload.CampaignNameFor}`);
      console.log("response from common.js is", res);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getCampaignLeadListing = (ChannelId, PageNo, NoOfRecordperPage, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${CAMPAIGN_LEAD_LISTING}${ChannelId}/${PageNo}/${NoOfRecordperPage}`, filterReq)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
};

export const getCscCampaignActivityListing = (payload, ChannelId, PageNo, NoOfRecordperPage) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${CSC_CAMPAIGN_ACTIVITY_LISTING}${ChannelId}/${PageNo}/${NoOfRecordperPage}`, payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}

export const getFilteredBusinessDataGridLeadList = (ChannelId, PageNo, NoOfRecordperPage, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${BUSSINESS_LEAD_LISTING}${ChannelId}/${PageNo}/${NoOfRecordperPage}`, filterReq);
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
};
export const getPersistancyLeadListing = (ChannelId, PageNo, NoOfRecordperPage, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${PERSISTANCY_LEAD_LISTING}${ChannelId}/${PageNo}/${NoOfRecordperPage}`, filterReq)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}

export const downloadBusinessLeads = (payload, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_BUSINESS_LIST}${payload.ChannelId}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const downloadGCLBusinessLeads = (payload, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_GCL_BUSINESS_LIST}${payload.ChannelId}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
export const downloadPersistencyLeads = (payload, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_PERSISTENCY_LIST}${payload.ChannelId}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const downloadCampaignLeads = (payload, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_CAMPAIGN_LIST}${payload.ChannelId}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
export const downloadCampaignActivities = (payload, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_CAMPAIGNACTIVITY_LIST}${payload.ChannelId}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
export const downloadBrocaActivities = (payload, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_BROCA_ACTIVITY_LIST}?ChannelID=${payload.ChannelId}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
export const downloadBrocaLead = (payload, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_BROCA_LEAD_LIST}?ChannelID=${payload.ChannelId}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
export const downloadGCLActivities = (payload, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_GCL_ACTIVITY_LIST}${payload.ChannelId}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const downloadGCLCampaignActivitiesWithId = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_GCL_ACTIVITY_LIST}${payload.ChannelId}?Ids=${payload.ids ? payload.ids : ""}`, {});
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const downloadAbhSmaActivities = (payload, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_ABHSMAACTIVITY_LIST}?ChannelID=${payload.ChannelId}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
export const downloadCampaignActivitiesWithId = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_CAMPAIGNACTIVITY_LIST}${payload.ChannelId}?Ids=${payload.ids ? payload.ids : ""}`, {});
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
export const downloadAbhSmaActivitiesWithId = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_ABHSMAACTIVITY_LIST}?ChannelID=${payload.ChannelId}&Ids=${payload.ids ? payload.ids : ""}`, {});
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const downloadCscCampaignActivities = (payload, filterReq) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_CSC_CAMPAIGNACTIVITY_LIST}${payload.ChannelId}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};
export const downloadCscCampaignActivitiesWithId = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_CSC_CAMPAIGNACTIVITY_LIST}${payload.ChannelId}?Ids=${payload.ids ? payload.ids : ""}`, {});
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};


export const downloadBusinessLeadsWithIds = (payload, filterReq) => (dispatch, getState) => {

  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_BUSINESS_LIST}${payload.ChannelId}?Ids=${payload.ids ? payload.ids : ""}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const downloadGCLBusinessLeadsWithIds = (payload, filterReq) => (dispatch, getState) => {

  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_GCL_BUSINESS_LIST}${payload.ChannelId}?Ids=${payload.ids ? payload.ids : ""}`, filterReq);
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};


export const downloadPersistencyLeadsWithIds = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_PERSISTENCY_LIST}${payload.ChannelId}?Ids=${payload.ids ? payload.ids : ""}`, {});
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const downloadCampaignLeadsWithIds = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_CAMPAIGN_LIST}${payload.ChannelId}?Ids=${payload.ids ? payload.ids : ""}`, {});
      if (res.status === 200) {
        resolve(res);
      }
    } catch (err) {
      reject(err);
    }
  });
};


export const checkInMap =
  ({
    status,
    latitude,
    longitude,
    id,
    address,
    setDrwerList,
    statusId,
    isCheckInAttendanceRequired,
    attendanaceRequestObj,
    isCampiagn
  }) =>
    async (dispatch) => {
      return new Promise(async (resolve, reject) => {
        const userData = JSON.parse(localStorage.getItem("persist:root"));
        const userId = JSON.parse(userData?.userReducer)?.profileInfo?.userId;
        dispatch(activateLoader());
        const data = {
          Address: address,
          Latitude: latitude,
          Longitude: longitude,
          LeadID: status === "checkinactivity" ? null : parseInt(id),
          TrnActivityID: status === "checkinactivity" ? parseInt(id) : null,
          UserID: userId,
          CreatedDate: new Date()
            .toISOString()
            .replace("T", " ")
            .replace("Z", ""),
          CreatedBy: userId.toString(),
          Checkinfor: isCampiagn ? "CampaignActivity" : status.replace("checkin", ""),
          LeadStatusId: status === "checkinactivity" ? null : parseInt(statusId),
          ActivityStatusId:
            status === "checkinactivity" ? parseInt(statusId) : null,
        };
        try {
          await post(CHECKIN_URL + status, data).then((response) => {
            dispatch(deActivateLoader());
            resolve(response.data);
          });
        } catch (e) {
          reject(false);
          setDrwerList(false);
          dispatch(activateErrorMessageBar(e.response.data.Responsemessage));
          dispatch(deActivateLoader());
        }
      });
    };
export const lmsHrmsCheckIn = (payload) => async (dispatch) => {
  dispatch(activateLoader());
  await postIntegration(LMS_HRMS_CHECKIN_URL, payload, FUNCTIONS_KEY_CHECKIN)
    .then((response) => {
      console.log("lmsHrmsCheckIn response ", response);
      dispatch(deActivateLoader());
      if (response.data && response.data.length > 0 && response.data[0].responsestatus) {
        console.log("Attendance marked successfully", response.data);
        dispatch(activateSuccessMessageBar("Attendance marked successfully"));
      } else {
        dispatch(
          activateErrorMessageBar(
            "Error! Attendance could not be marked, please mark through HRMS."
          )
        );
      }
    })
    .catch((error) => {
      console.log("lmsHrmsCheckIn error ", error);
      dispatch(deActivateLoader());
      dispatch(
        activateErrorMessageBar(
          "Error! Attendance could not be marked, please mark through HRMS."
        )
      );
    });
};

export const DistrictList = (StateId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await get(DISTRICT_LIST + StateId).then((response) => {
        resolve(response);
      });
    } catch (e) {
      reject(e);
      console.log("error", e);
      dispatch(activateErrorMessageBar(e?.data?.Responsemessage));
    }
  });
};


// export const getAdvisorZMAPremierZMAListing =
//   (payload) => async (dispatch, getState) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         const res = await get(
//           `${GET_AGENCY_USER_MAPPING}/${payload.userId}/${payload.status}`,
//           {}
//         );
//         resolve(res);
//       } catch (err) {
//         reject(err);
//         dispatch(activateErrorMessageBar(err?.data?.Responsemessage));
//       }
//     });
//   };

// Sales Management Actitvity
export const getTrainingType = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_MASTER_DATA}${"TrainingType"}`);
      dispatch(smaTrainingType(res.data));
      resolve(res);
    } catch (err) {
      reject(err);
      dispatch(activateErrorMessageBar(err?.data?.Responsemessage));
    }
  });
};

export const getReasons = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_MASTER_DATA}${"smareasons"}`);
      dispatch(SmaReasons(res.data));
      resolve(res);
    } catch (err) {
      reject(err);
      dispatch(activateErrorMessageBar(err?.data?.Responsemessage));
    }
  });
};

// export const TrainingType = (payload) => (dispatch, getState) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await get(`${GET_MASTER_DATA}${'TrainingType'}`);
//       dispatch(SMATrainingType(res.data));
//       resolve(res);
//     } catch (err) {
//       reject(err);
//       dispatch(activateErrorMessageBar(err?.data?.Responsemessage));
//     }
//   });
// };

export const ActivityType = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_MASTER_DATA}${"ActivityType"}`);

      dispatch(SMAActivityType(res.data));

      resolve(res);
    } catch (err) {
      reject(err);

      dispatch(activateErrorMessageBar(err?.data?.Responsemessage));
    }
  });
};

export const getAcitvityDuration = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_MASTER_DATA}${"SmaActivityDuration"}`);
      // dispatch(SMAActivityDuration(res.data));
      resolve(res);
    } catch (err) {
      reject(err);
      dispatch(activateErrorMessageBar(err?.data?.Responsemessage));
    }
  });
};
export const getBDMBranchName = (payload) => (dispatch, getState) => {
  dispatch(activateLoader());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_BRANCH_DETAILS}/${payload}`);
      dispatch(getBranchDetails(res.data))
      resolve(res);
      // dispatch(deActivateLoader());
    } catch (err) {
      reject(err);
      dispatch(deActivateLoader());
    }
  });
};

export const exportToExcel = (downloadLink) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = getDownloadDatas(CONVERT_TO_EXCEL, downloadLink)
      resolve(res);
      dispatch(deActivateLoader());
    } catch (err) {
      //console.log(error)
      reject(err);
    }
  });
}

export const getBdmBankRegion = (payload) => (dispatch, getState) => {
  dispatch(activateLoader());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_SMA_REGION_MAP_BY_USERID}${payload.userId}?Level=${payload.role}`);
      dispatch(smaBankRegion(res.data));
      dispatch(deActivateLoader());
      resolve(res);
    } catch (err) {
      reject(err);
      dispatch(deActivateLoader());
      dispatch(activateErrorMessageBar(err?.data?.Responsemessage));
    }
  });
};

export const getActivityTypeDetailsCountsByUserId =
  (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await post(GET_ACTIVITY_TYPE_DETAILS_COUNTS_BY_USER_ID, payload);
        dispatch(activityTypeDetailCount(res.data));
        resolve(res);
      } catch (err) {
        reject(err);
        dispatch(activateErrorMessageBar(err?.data?.Responsemessage));
      }
    });
  }
export const getAdvisorZMAPremierZMAListing = (payload) => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${GET_AGENCY_USER_MAPPING}/${payload.userId}/${payload.status}`, {})
      if (payload.status == "ZMA" && res.data.length > 0) {
        dispatch(setZMAList(res.data))
      }
      if (payload.status == "Advisor" && res.data.length > 0) {
        dispatch(setAdvisorList(res.data))
      }
      if (payload.status == "Premier ZMA" && res.data.length > 0) {
        dispatch(setPremierZMAList(res.data))
      }
      if (payload.status == "EAP" && res.data.length > 0) {
        dispatch(setEAPList(res.data))
      }
      resolve(res)
    } catch (err) {
      reject(err)
      dispatch(activateErrorMessageBar(err?.data?.Responsemessage));
    }
  })

}


export const getActivityCsvFile = (payload, userID, roleID, userType) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${GET_ACTIVITY_BY_CSV_FILE}?activitytype=${"activity"}&UserId=${userID}&rolename=${roleID}&viewFor=${userType}&subordinateUserIds=${"all"}`, payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}
export const getLeadCsvFile = (payload, userID, leadType, roleID, userType) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${GET_LEAD_BY_CSV_FILE}?leadstype=${leadType}&UserId=${userID}&rolename=${roleID}&viewFor=${userType}&subordinateUserIds=${"all"}`, payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}

export const downloadBranchPartnerCategorywise = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_BRANCH_PARTNER_AND_CATEGORYWISE}`,payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}

export const downloadBrocaReportMustdoActivity = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_MUST_DO_ACTIVITY_SUMMARY }`,payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}
export const downloadBrocaReportBusinessDevelopment = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_BUSINESS_DEVELOPMENT_ACTIVITY }`,payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}
export const downloadBrocaReportBFDWiseActivity = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_BFD_WISE_ACTIVITY }`,payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}
export const downloadBrocaReportPartnerEngagementActivity = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${DOWNLOAD_PARTNER_ENGAGEMENT_ACTIVITY }`,payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}
export const downloadBrocaReportLeadGenerationActivity = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(DOWNLOAD_LEAD_GENERATION_ACTIVITY ,payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}


export const getPartnerDetails = (payload) => (dispatch, getState) => {
  dispatch(activateLoader());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(GET_PARTNER_DETAILS);
      dispatch(getPatnerDetails(res.data));
      dispatch(deActivateLoader());
    } catch (err) {
      reject(err);
      dispatch(deActivateLoader());
    }
  });
};

export const setHypercollateralFlag = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${SET_COLLATERAL_FLAG}`,payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}

export const validatePersistencyLeadForUpdate = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await get(`${VALIDATE_PERSISTENCY_LEAD_FOR_UPDATE}/${payload}`,)
      dispatch({
          type:"validatePersistencyLead",
          payload:res.data.Responsestatus
      })
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      reject(err)
    }
  })
}
export const getOtpForResetDevice = (payload) => (dispatch, getState) => {
  dispatch(activateLoader());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${GET_OTP_FOR_RESET_DEVICE}?Userid=${payload}`,{})
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: "Something went wrong! please try again later."})
      reject(err)
    }
  })
}
export const validateOtpForResetDevice = (payload) => (dispatch, getState) => {
  dispatch(activateLoader());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${VALIDATE_OTP_FOR_RESET_DEVICE}?IsResetDevice=true`,payload)
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: "Something went wrong! please try again later."})
      reject(err)
    }
  })
}
export const validateUserForResetDevice = (payload) => (dispatch, getState) => {
  console.log(" i am call 1")
  dispatch(activateLoader());
  return new Promise(async (resolve, reject) => {
    try {
      console.log(" i am call 2")
      const res = await get(`${VALIDATE_USER_RESET_DEVICE}`,payload)
      console.log(" i am call 3")
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: "Something went wrong! please try again later."})
      reject(err)
    }
  })
}

export const setUserConsent = (payload) => (dispatch, getState) => {
  dispatch(activateLoader());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await post(`${VALIDATE_USER_CONSENT_DEVICE}?UserId=${payload.userId}&isConsent=true`,{})
      dispatch(deActivateLoader());
      resolve(res)
    } catch (err) {
      dispatch(deActivateLoader());
      dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: "Something went wrong! please try again later."})
      reject(err)
    }
  })
}


